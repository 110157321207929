import { graphql } from "gatsby"
import SEO, { getSeoProps } from "../components/Common/SEO"
import getBannerProps from "../components/Hero/getHeroBannerProps"
import { Paginator } from "../components/Search/Pagination"
import { useState } from "react"
import HeroAktueltHeadline from "../components/Hero/HeroAktueltHeadline"
import AktueltCards from "../components/Cards/AktueltCards"
import getMainPath from "../components/Common/MainPaths"
import { SortByDate } from "../components/Common/DateConverter"

/**
 * @description Bygges opp likt som de andre sidene, oversikt, ingress, visning av kort
 * kort sorteres etter nyeste først (created date)
 * @param param0
 */

const Tema = ({ location, data }) => {
  const mainPath = getMainPath("topics")
  const [filter, setFilter] = useState(null)
  const [page, setPage] = useState(1)

  const articlesPerPage = 12

  const {
    allContentfulTopics: { nodes: allContent },
    contentfulGeneric: content,
  } = data

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")
  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  // Applys filter
  const filteredContent = allContent.filter((article) => {
    if (!filter) return true
    return article.id === filter.id
  })

  const filteredContentArray: Array<unknown> = []
  const uniqueContentId: Array<string> = []
  filteredContent.forEach((array) => {
    array.article.forEach((article) => {
      if (uniqueContentId.indexOf(article.id) !== -1) {
        // filteredContentArray[uniqueContentId.indexOf(article.id)].category.push({
        //   category: array.title,
        //   slug: array.slug,
        // })
      } else {
        uniqueContentId.push(article.id)
        article.category = { category: array.title, slug: array.slug }
        filteredContentArray.push(article)
      }
    })
  })

  // get the maxNumber of pages
  const numPages = Math.ceil(filteredContentArray.length / articlesPerPage)

  // articles to render
  const standardArticles = filteredContentArray.slice((page - 1) * articlesPerPage, articlesPerPage * page)

  const uniqueCategory = allContent.map((category) => {
    return { title: category.title, id: category.id, slug: category.slug }
  })

  const handleChange = (e) => {
    if (e.target.value === "alle") {
      setFilter(null)
      return
    }
    setFilter(uniqueCategory.find(({ id }) => id === e.target.value))
  }

  // Sort all related articles from new to old. Let GH override with createdAtModified
  const sortedContent = SortByDate(standardArticles)

  return (
    <>
      <SEO {...helmetProps} />
      <main className="flex justify-center">
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 lg:max-w-7xl">
          <header>
            <HeroAktueltHeadline {...heroBannerProps} />
          </header>
          <div className="hidden lg:block p-6 mt-8 bg-transparent mx-auto max-w-4xl">
            <ul className="flex justify-around sm:flex-row sm:flex-wrap">
              <button
                className="bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:outline-none focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:border-transparent border-none hover:border-transparent rounded-full"
                role="menuitem"
                onClick={() => setFilter(null)}
                // autofocus="true"
              >
                Vis alle #
              </button>

              {uniqueCategory.map((category) => {
                return (
                  <button
                    key={category.id}
                    className="bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:border-transparent  focus:outline-none border-none hover:border-transparent rounded-full"
                    role="menuitem"
                    onClick={() => {
                      setFilter(category)
                    }}
                    // autofocus="true"
                  >
                    #{category.title}
                  </button>
                )
              })}
            </ul>
          </div>

          <div className="flex lg:hidden justify-center ">
            <select
              defaultValue={"DEFAULT"}
              onChange={handleChange}
              id="location"
              name="location"
              className="mt-1 w-auto text-base border-gray-300 hover:outline-none focus:outline-none  sm:text-sm  rounded-3xl bg-white squash-500 focus:ring-none"
            >
              <option value="alle">Vis alle #</option>
              {uniqueCategory.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="flex justify-center">
            <div className="mt-12 max-w-lg mx-auto grid gap-24 lg:grid-cols-3 lg:max-w-none">
              {sortedContent.map((article) => {
                const props = {
                  data: article,
                  url: "nb" + mainPath,
                  height: "lg:h-72",
                  topSlug: "tema",
                }
                return <AktueltCards key={article.id} {...props} />
              })}
            </div>
          </div>
          <div className="mt-16">
            <Paginator maxPage={numPages} currentPage={page} changePage={(e) => setPage(e)} />
          </div>
        </div>
      </main>
    </>
  )
}

export const topicQuery = graphql`
  {
    allContentfulTopics(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        contentful_id
        slug
        node_locale
        title
        createdAt(formatString: "YYYY-MM-D")
        updatedAt(formatString: "YYYY-MM-D")
        metadataDescription
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        article {
          id
          slug
          title
          createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
          createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
          updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
          articleType
          ingress {
            ingress
          }
          heroImage {
            id
            public_id
            context {
              custom {
                Credits
                alt
              }
            }
          }
        }
      }
    }
    contentfulGeneric(slug: { eq: "tema" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      body {
        childMdx {
          timeToRead
          body
        }
      }
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      node_locale
      title
      metadataDescription
      slug
    }
  }
`

export default Tema
