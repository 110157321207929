import { Ingress } from "../Styling/Body"
import { HeroTitle } from "../Styling/Titles"

const HeroHeadline = (props) => {
  return (
    <>
      <header>
        <HeroTitle>
          <span className="text-center">{props.title}</span>
        </HeroTitle>
        <div className="flex justify-center mt-16">
          <div className="lg:w-6/12">
            <Ingress>{props.ingress}</Ingress>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroHeadline
